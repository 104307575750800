import { memo, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import GlidePathOrganism from '../../components/organisms/charts/glide-path';
import { useAppDispatch } from '../../app/hooks';
import { formatDataForGlidePathCharts, getCurrentYear } from '../../utils/charts';
import { UnknownProperties } from '../../constants/types';
import { useAppSelector } from '../../app/hooks';

const GlidePathContainer = ({
    response,
    request,
    portfolioData,
    updateProfolioCompositionData,
    disableDispatch = false,
    ...rest
}: any) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [xAxisCategories, setXAxisCategories] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [equityData, setEquityData] = useState([]);
    const [fixedIncomeData, setFixedIncomeData] = useState([]);
    const [equityPosition, setEquityPosition] = useState([]);
    const [fixedIncomePosition, setFixedIncomePosition] = useState([]);
    const enableSourceCaveatNumbers = useAppSelector((state) =>
        Boolean(state.global.orgInfo?.enableSourceCaveatNumbers)
    );
    const tenantId = useAppSelector((state) => state?.global?.globalConfig?.subdomain);

    useEffect(() => {
        if (!isEmpty(response) && !isEmpty(portfolioData) && !isEmpty(request)) {
            const portfolio_path = response?.pathReport?.portfolioPath;

            const years: any =
                request?.annualDistribution?.years.map((val: { toString: () => string }) => val.toString()) || [];

            const { series, fixedIncome, equity, equityLabelPosition, fixedIncomeLabelPosition }: UnknownProperties =
                formatDataForGlidePathCharts(portfolio_path, portfolioData, tenantId === 'banorte');

            const equityLabelValue = JSON.parse(series[years.indexOf(getCurrentYear())]?.portfolio).find(
                (v: { type: string }) => v.type === 'Equity'
            ).totalAllocatedvalue;

            setXAxisCategories(years);
            setSeriesData(series);
            setEquityData(equity);
            setFixedIncomeData(fixedIncome);
            setEquityPosition(equityLabelPosition);
            setFixedIncomePosition(fixedIncomeLabelPosition);
            if (!disableDispatch) {
                dispatch(
                    updateProfolioCompositionData({
                        data: JSON.parse(series[years.indexOf(getCurrentYear())]?.portfolio),
                        year: getCurrentYear(),
                        equity: equityLabelValue,
                        fixedIncome: 100 - equityLabelValue
                    })
                );
            }
        }
    }, [response, portfolioData, request]);

    const translation = {
        equity_header: t('HEADER_EQUITY'),
        equity_sub_header: t('HEADER_SUB_EQUITY'),
        fixed_income_header: t('HEADER_FIXED_INCOME'),
        fixed_income_sub_header: t('HEADER_SUB_FIXED_INCOME')
    };

    return (
        <GlidePathOrganism
            theme={theme}
            xAxisCategories={xAxisCategories}
            equityPosition={equityPosition}
            fixedIncomePosition={fixedIncomePosition}
            dispatch={dispatch}
            updateProfolioCompositionData={updateProfolioCompositionData}
            fixedIncomeData={fixedIncomeData}
            equityData={equityData}
            seriesData={seriesData}
            translation={translation}
            enableSourceCaveatNumbers={enableSourceCaveatNumbers}
            {...rest}
        />
    );
};

export default memo(GlidePathContainer);
